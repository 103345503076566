/* eslint-disable @typescript-eslint/explicit-function-return-type */

/**
 * NOTE: This file is purposefully not TypeScript as it is used by
 * both the React components and Gatsby Node API to resolve URLs.
 */

/**
 * Resolve the relative URL for the specified node type and slug.
 * @param {String} type
 * @param {String} slug
 */
module.exports = function resolveUrl(type, slug) {
  switch (type) {
    case 'blog_article':
    case 'kontent_item_blog_article':
      return `/blog/${slug}`;

    case 'blog_category':
    case 'kontent_item_blog_category':
      return `/blog/${slug}`;

    case 'blog_landing':
    case 'kontent_item_blog_landing':
      return `/blog`;

    case 'case_study':
    case 'kontent_item_case_study':
      return `/case-studies/${slug}`;

    case 'case_study_landing':
    case 'kontent_item_case_study_landing':
      return `/case-studies`;

    case 'content_page':
    case 'kontent_item_content_page':
      return `/${slug}`;

    case 'home':
    case 'kontent_item_home':
      return `/`;

    case 'knowledge_base':
    case 'kontent_item_knowledge_base':
      return `/support`;

    case 'knowledge_base_article':
    case 'kontent_item_knowledge_base_article':
      return `/support/${slug}`;

    case 'knowledge_base_category':
    case 'kontent_item_knowledge_base_category':
      return `/support/${slug}`;

    default:
      return null;
  }
};
