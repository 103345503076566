export let ComponentThemeTaxonomy;
(function (ComponentThemeTaxonomy) {
    ComponentThemeTaxonomy["BLUE"] = "blue";
    ComponentThemeTaxonomy["GREEN"] = "green";
    ComponentThemeTaxonomy["GREEN_ALT"] = "green_alt";
    ComponentThemeTaxonomy["LIGHT_GREY"] = "light_grey";
    ComponentThemeTaxonomy["LILAC"] = "lilac";
    ComponentThemeTaxonomy["PURPLE"] = "purple";
    ComponentThemeTaxonomy["PINK"] = "pink";
    ComponentThemeTaxonomy["YELLOW"] = "yellow";
})(ComponentThemeTaxonomy || (ComponentThemeTaxonomy = {}));
export let SectionFlush;
(function (SectionFlush) {
    SectionFlush["NONE"] = "";
    SectionFlush["TOP"] = "top";
    SectionFlush["BOTTOM"] = "bottom";
})(SectionFlush || (SectionFlush = {}));
