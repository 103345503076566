import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly)
        symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; });
    keys.push.apply(keys, symbols);
} return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};
    if (i % 2) {
        ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); });
    }
    else if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    }
    else {
        ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); });
    }
} return target; }
import { useState, useLayoutEffect } from 'react';
function useInView(elementRef, options = {}) {
    const [inView, setInView] = useState(false);
    useLayoutEffect(() => {
        if (!elementRef.current) {
            return;
        }
        const observerRef = new IntersectionObserver(entries => {
            var _entries$0$isIntersec, _entries$;
            setInView((_entries$0$isIntersec = entries === null || entries === void 0 ? void 0 : (_entries$ = entries[0]) === null || _entries$ === void 0 ? void 0 : _entries$.isIntersecting) !== null && _entries$0$isIntersec !== void 0 ? _entries$0$isIntersec : false);
        }, _objectSpread({
            rootMargin: '0px 0px -20% 0px'
        }, options));
        observerRef.observe(elementRef.current);
        return () => {
            if (observerRef) {
                observerRef.disconnect();
            }
        };
    }, []);
    return inView;
}
export default useInView;
