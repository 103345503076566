import { useState, useEffect } from 'react';
import useInView from './useInView';
function useWasSeen(elementRef, options = {}) {
    const isInView = useInView(elementRef, options);
    const [isViewed, setIsViewed] = useState(false);
    useEffect(() => {
        if (isInView) {
            setIsViewed(true);
        }
    }, [isInView]);
    return isViewed;
}
export default useWasSeen;
