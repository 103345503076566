export let CtaMode;
/**
 *
 * @param text
 * @param external
 * @param internal
 */
(function (CtaMode) {
    CtaMode["LINK"] = "link";
    CtaMode["REQUEST_A_DEMO_FORM"] = "request_a_demo_form";
})(CtaMode || (CtaMode = {}));
export default function getCtaModel(text, external, internal, mode = CtaMode.LINK) {
    let to = null;
    let isExternal = false;
    if (external) {
        isExternal = true;
        to = external;
    }
    const nodeWithUrl = internal;
    if (nodeWithUrl) {
        to = nodeWithUrl.url || '';
    }
    return {
        isExternal,
        mode: mode,
        text: text || '',
        to: to || ''
    };
}
