import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
import { useRef, useEffect } from 'react';
export default function useBodyScrollLock(isEnabled) {
    const ref = useRef(null);
    useEffect(() => {
        if (ref.current) {
            if (isEnabled) {
                enableBodyScroll(ref.current);
            }
            else {
                disableBodyScroll(ref.current);
            }
        }
        return () => {
            if (ref.current) {
                enableBodyScroll(ref.current);
            }
        };
    }, [isEnabled]);
    return ref;
}
