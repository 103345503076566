export let AnimationVariants;
(function (AnimationVariants) {
    AnimationVariants["HIDDEN"] = "HIDDEN";
    AnimationVariants["NOT_SEEN"] = "NOT_SEEN";
    AnimationVariants["SEEN"] = "SEEN";
    AnimationVariants["VISIBLE"] = "VISIBLE";
})(AnimationVariants || (AnimationVariants = {}));
export const FadeUpVariants = {
    [AnimationVariants.NOT_SEEN]: {
        opacity: 0,
        y: 50
    },
    [AnimationVariants.SEEN]: {
        opacity: 1,
        y: 0
    }
};
export const NoStaggerChildren = {
    [AnimationVariants.NOT_SEEN]: {
        transition: {
            staggerChildren: 0
        }
    },
    [AnimationVariants.SEEN]: {
        transition: {
            staggerChildren: 0
        }
    }
};
export const StaggerChildren = {
    [AnimationVariants.NOT_SEEN]: {
        transition: {
            staggerChildren: 0.1,
            staggerDirection: -1
        }
    },
    [AnimationVariants.SEEN]: {
        transition: {
            staggerChildren: 0.1
        }
    }
};
export const ZoomVariants = {
    [AnimationVariants.NOT_SEEN]: {
        opacity: 0,
        scale: 0.2
    },
    [AnimationVariants.SEEN]: {
        opacity: 1,
        scale: 1
    }
};
