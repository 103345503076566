export let ButtonColor;
(function (ButtonColor) {
    ButtonColor["GREEN"] = "buttonGreen";
    ButtonColor["PINK"] = "buttonPink";
    ButtonColor["YELLOW"] = "buttonYellow";
    ButtonColor["PURPLE"] = "buttonPurple";
    ButtonColor["BLUE"] = "buttonBlue";
})(ButtonColor || (ButtonColor = {}));
export let ButtonSize;
(function (ButtonSize) {
    ButtonSize[ButtonSize["NORMAL"] = 0] = "NORMAL";
    ButtonSize["SMALL"] = "buttonSmall";
})(ButtonSize || (ButtonSize = {}));
