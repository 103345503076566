/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { loadableReady } from '@loadable/component';
import { render } from 'react-dom';

/** https://www.gatsbyjs.org/docs/browser-apis/#replaceHydrateFunction */
export const replaceHydrateFunction = () => {
  return (element, container, callback) => {
    loadableReady(() => {
      render(element, container, callback);
    });
  };
};
