import "core-js/modules/es6.regexp.split";
export function getCookieValue(name) {
    var _cookies$find, _cookies$find$split;
    const cookies = document.cookie.split('; ');
    const cookieValue = cookies === null || cookies === void 0 ? void 0 : (_cookies$find = cookies.find(row => row.startsWith(name))) === null || _cookies$find === void 0 ? void 0 : (_cookies$find$split = _cookies$find.split('=')) === null || _cookies$find$split === void 0 ? void 0 : _cookies$find$split[1];
    return cookieValue;
}
export function setCookieValue(name, value) {
    document.cookie = "".concat(name, "=").concat(value);
}
